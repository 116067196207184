body{
  font-family:'Poppins' !important;
  background-color:#0F0F0F !important;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  min-height: 150vh;
}
.container{
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.desktop-show{
  display: block;
}
.mobile-show{
  display: none !important;
}
.desktop-flex{
  display: flex;
}
.opacity-20{
  opacity: 0.2 !important;
}

/* start header css */
.menu-toggle{
  background: #181B1A;
  border-radius: 10px;
  padding: 7px 10px;
}
.chain-menu{
  background: rgb(0, 0, 0);
  color: white;
  box-shadow: inset 0 4px 19px rgba(0, 0, 0, 0.16), 0 4px 20px 0 rgba(78, 89, 97, 0.15);
  width:240px;
  border-radius: 8px;
  padding: 20px 15px;
  position: absolute;
  right: 0px;
  top: 64px;
  z-index: 99;
}
.chain-menu-item{
  border-radius: 8px;
}
.chain-menu-item:hover{
  background: rgba(178, 200, 208, 1);
}
.menu-item.active_menu{
  color:white;
  font-weight: 700;
}
/* .btn.connect_btn{
  background-color: #18283300 ;
  border: 2px solid #181B1A ;
  color:white;
  border-radius: 10px ;
  font-size:14px ;
  font-weight: 500 ;
  line-height: 24px ;
  padding: 10px 26px ;
  margin: 10px 10px;
  text-align: center;
} */
.connect_btn{
  background-color: #18283300 !important;
  border: 2px solid #181B1A !important;
  color:white;
  border-radius: 10px !important;
  font-size:14px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  padding: 10px 26px !important;
  margin: 10px 10px;
  text-align: center;
}
.connect_btn:hover{
  transform: scale(1.025);
  transition: all 0.1s;
}
.connect_btn_mobile{
  position: absolute;
  right: 56px;
}
/* end header css */


/* start footer css */
.footer-bg{
  background-color: #181B1A;
  padding: 40px 0;
}
.footer-menu-item{
  padding: 5px 0 !important;
}
.footer-social-item {
  background: #222725;
  margin-right: 10px;
  margin-top: 10px !important;
  border-radius: 4px;
  padding: 5px 8px;
}
/* end footer css */



/* width css */
.width-100{
  width: 50% !important;
}
.width-33-100{
  width:33% !important;
  margin-top: 0px !important;
}
.width-33-50{
  width:33% !important;
}
.w-25-100{
  width:25% !important;
}
.w-33-100{
  width:33.33333333% !important;
}
.w-41-100{
  width:41% !important;
}
.w-59-100{
  width:59% !important;
}
.w-75-100{
  width:75% !important;
}
.w-58px{
  width:58px;
}
.w-450px{
  width:450px;
}
.min-h-270{
  min-height: 292px;
}
.min-h-357{
  min-height: 357px;
}
.min-h-455{
  min-height: 455px ;
}
.min-h-500{
  min-height: 500px ;
}

/* Start align padding css */
.v-center{
  align-items: center;
}
.space-between{
  justify-content: space-between;
}
.space-around{
  justify-content: space-around;
}
.flex-center{
  justify-content: center;
}
.flex-end{
  justify-content: flex-end;
}
.positoin-relative{
  position: relative;
}
.text-line{
  text-decoration: underline;
}
.none-text-line{
  text-decoration: none;
}
.cursur-pointer{
  cursor: pointer;
}
.p-10-25{
  padding: 10px 25px !important;
}
.p-0-12{
  padding: 0px 12px !important;
}
.p-0-20{
  padding: 0px 20px !important;
}
.ptb-50{
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.mr-10{
  margin-right: 10px;
}
.ml-10{
  margin-left: 10px;
}
.mr-20{
  margin-right: 20px;
}
.m-0{
  margin:0 !important;
}
.mobile-left{
  text-align: center;
}
/* End align css */



/* Start font size, color css */
.font-12{
  font-size: 12px;
  line-height: 18px;
}
.font-14{
  font-size: 14px;
  line-height: 21px;
}
.font-16{
  font-size: 16px;
  line-height: 26px;
}
.font-18{
  font-size: 18px;
  line-height: 27px;
}
.font-20{
  font-size: 20px ;
  line-height: 1.3em;
}
.font-22{
  font-size: 22px ;
  line-height: 1.3em;
}
.font-24{
  font-size: 24px ;
  line-height: 1.3em;
}
.font-32{
  font-size: 32px ;
  line-height: 1.3em;
}
.font-36{
  font-size: 36px ;
  line-height: 1.3em;
}
.bold-300{
  font-weight: 300 ;
}
.bold-400{
  font-weight: 400 ;
}
.bold-500{
  font-weight: 500 ;
}
.bold-600{
  font-weight: 600 !important;
}
.bold-700{
  font-weight: 700 !important;
}
.color-white-50{
  color: rgba(255, 255, 255, 0.5);
}
.color-white{
  color: white !important;
}
.color-gray{
  color: #B1B1B1 !important;
}
.color-dark{
  color: #0F0F0F !important;
}
.color-green{
  color:#19FB80 !important;
}
.color-green1{
  color:#50CF86 !important;
}
.bg-trans{
  background: #D1FEE60D !important;
}
.bg-trans1{
  background: #181B1A !important;
}
.bg-trans2{
  background: #222725 !important;
}
.bg-trans-0{
  background: #ffffff00 !important;
}
.bg-green{
  background: #19FB80 !important;
}
.bg-dark{
  background: #181B1A !important;
}
.bg-gray{
  background: #2C312F !important;
}

.border-green{
  border-color: #19FB80 !important;;
}
.border-green1{
  border-color: #50CF86 !important;;
}
.border-dark-green{
  border-color:#262D2A !important;
}
.border-white{
  border-color: white !important;
}
.border-gray{
  border-color: #353D39 !important;
}
.border-dash{
  border: 1px solid #414D48;
  border-style: dashed;
}
/* End font size, color css */


/* Other css */
.radius-8{
  border-radius: 8px !important;
}
.border-left-radius-8{
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.border-right-radius-8{
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.border-1{
  border: 1px solid;
}
.border-2{
  border: 2px solid;
}
.border-1-right{
  border-right: 1px solid;
}
.border-1-left{
  border-left: 1px solid;
}
.hr-1{
  border-top: 1px solid;
  opacity: 1;
}
.hr-3{
  border-top: 3px solid;
  opacity: 1;
}
/* Home Page Css */
.home-section1{
  background-image: url('../img/home-bg.svg');
  min-height: 820px;
  margin-top: -118px;
  padding-top: 170px;
  background-repeat: repeat;
  background-size: cover !important;
}
.home-section4{
  background-image: url('../img/home_img6.svg');
  background-repeat: repeat;
  background-size: cover !important;
}
.w-238{
  width:238px !important;
}
.btn_apply_for_whitelist{
  margin-right:20px;
}
.section1-img{
  position: absolute;
  top: 0;
  right: 0;
}
.section1-img-light{
  position: fixed;
  top: 0;
  right: 0;
  z-index: -9;
}
.section1-img-light1{
  position: fixed;
    top: -50%;
    right: -89%;
    z-index: -9;
}
.accordion-item{
  background: #ffffff00 !important;
  border:none !important;
  border-bottom: 1px solid white !important;
  border-radius: 0 !important;
}
.accordion-body {
  padding: 17px 0 !important;
}
.accordion-button{
  background: #ffffff00 !important;
  color:white !important;
  font-size:22px !important;
  font-weight: 700 !important;
  padding: 25px 0 !important;
}
.accordion-button:focus{
  border:none !important;
  box-shadow: none !important;
}
.accordion-button::after{
  background-image: url('../img/accordion-arrow.svg') !important;
}

/* Sidebar css */
.sidebar_social{
  position: fixed;
  right:0;
  top:200px;
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border: 1px solid #262D2A
  ;
}
.sidebar_social>div{
  width: 40px !important;
  height: 40px !important;
  background: #222725;
  padding: 8px 10px;
}
.border-top-left-8{
  border-top-left-radius: 8px !important;
  border-color: #262D2A;
}
.border-bottom-left-8{
  border-bottom-left-radius: 8px !important;
  border-color: #262D2A;
}
.social-border-bottom{
  border-bottom: 2px solid #262D2A !important;
}

/* title popup css */
.info[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
.info[data-title]:hover:before {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
.info[data-title]:before {
  content: "";
  position: absolute;
  top: 20px;
  right: 0px;
  z-index: 10;
  opacity: 0;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 20px 30px 20px;
  border-color: transparent transparent #262D2A transparent;
}
.info[data-title]:after {
  content: attr(data-title);
  background-color: #262D2A;
  color: white;
  border-radius: 8px;
  position: absolute;
  padding: 20px;
  bottom: -129px;
  left: 50%;
  opacity: 0;
  border: 1px solid #262D2A;
  z-index: 10;
  visibility: hidden;
  margin-left: -50px;
  min-width: 250px;
  width: 100%;
  min-height: 120px;
}
.info[data-title] {
  position: relative;
}
 
/* GMI Page Css */
.active_gmi_tab{
  border-color:#19FB80 !important;
}
.gmi_dropdown{
  position: absolute;
  right: 16px;
  top: 46px;
  width: 97%;
  z-index: 9999999;
}
.gmi_dropdown .font-16{
  font-size:13px !important;
}
.d-block{
  display: block !important;
}
.d-none{
  display: none !important;
}
.gmi_dropdown .table>:not(caption)>*>*, .gmi_basket_table.table>:not(caption)>*>*{
  background-color: #fff0 !important;
}
.gmi_dropdown_table>tbody>tr:hover{
  background: #363B39 !important;
}
.btn-swap{
  margin-top: -19px;
  margin-bottom: -19px;
  z-index: 999;
  position: relative;
}
.btn-swap>img{
  border: 2px solid black;
  border-radius: 100%;
}
.gmi_dropdown_table>tbody>tr>td:first-child{
  min-width: 106px;
}
/* Range Slider Css */
/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
input[type=range].styled-slider {
  -webkit-appearance: none;
}

/*progress support*/
input[type=range].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 32px + var(--ratio) * (100% - 32px));
}

input[type=range].styled-slider:focus {
  outline: none;
}

/*webkit*/
input[type=range].styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 32px;
  height: 32px;
  border-radius: 3em;
  background: #19FB80;
  border: none;
  box-shadow: 0 0 2px #00000000;
  margin-top: calc(32px * 0.5 - 32px * 0.5);
}

input[type=range].styled-slider::-webkit-slider-runnable-track {
  height: 32px;
  border: none;
  border-radius: 36px;
  background: #18482E;
  box-shadow: none;
}

input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#19FB80,#19FB80) 0/var(--sx) 100% no-repeat, #18482E;
}

/*mozilla*/
input[type=range].styled-slider::-moz-range-thumb {
  width: 32px;
  height: 32px;
  border-radius: 3em;
  background: #19FB80;
  border: none;
  box-shadow: 0 0 2px #00000000;
}

input[type=range].styled-slider::-moz-range-track {
  height: 32px;
  border: none;
  border-radius: 36px;
  background: #18482E;
  box-shadow: none;
}

input[type=range].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient(#19FB80,#19FB80) 0/var(--sx) 100% no-repeat, #18482E;
}

/*ms*/
.styled-slider{
  background: #ffffff00 !important;
}
input[type=range].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-thumb {
  width: 32px;
  height: 32px;
  border-radius: 3em;
  background: #19FB80;
  border: none;
  box-shadow: 0 0 2px #00000000;
  margin-top: 0;
  box-sizing: border-box;
}

input[type=range].styled-slider::-ms-track {
  height: 32px;
  border-radius: 36px;
  background: #18482E;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
}

input[type=range].styled-slider.slider-progress::-ms-fill-lower {
  height: 32px;
  border-radius: 36px 0 0 36px;
  margin: -undefined 0 -undefined -undefined;
  background: #19FB80;
  border: none;
  border-right-width: 0;
}
/* End Range Slider Css */
.range_value{
  position: absolute;
  top: 6px !important;
}

@-moz-document url-prefix() {
  .range_value{
    top: 1px !important;
  }
}

.modal-content {
  background-color: #ffffff00 !important;
}
.buy_gloop_inputs{
  background: #ffffff00 !important;
  border: none !important;
  text-align: right !important;
  color: #b1b1b1;
}
input.buy_gloop_inputs:focus-visible {
  outline: unset;
}
.buy_gmi_inputs{
  background: #ffffff00 !important;
  border: none !important;
  color: #ffffff;
  padding: 0;
  font-weight: 700;
  width: 200px;
}
input.buy_gmi_inputs:focus-visible {
  outline: unset;
}
input.buy_gmi_inputs::placeholder {
  color: white;
}
/* Vault Page Css */
.vault_asset_item{
  border-color:#181B1A;
  cursor: pointer;
}
.vault_asset_item:hover{
  border:1px solid #19FB80 !important;
}
.vault_asset_item_active{
  border:1px solid #19FB80 !important;
}
.collateral_ratio{
  margin-left:0;
}
.modal-content, .modal-body{
  z-index: 99999999 !important;
}
/* 
button:active {
  transform: translateY(2px);
} */
.gloop-btn-primary:active, .gloop-btn-primary:focus {
  border-color: #0f974d !important;
  background: #0F974D !important;
}
.gloop-btn-primary:hover {
  border-color: #14c966 !important;
  background: #14C966 !important;
}

.gloop-btn-second:active, .gloop-btn-second:hover, .gloop-btn-second:focus {
  border-color: #14c966 !important;
}

.btn.max-btn, .btn.max-btn:hover, .btn.max-btn:active{
  background-color: #ffffff00 !important;
}
.modal.show{
  backdrop-filter: blur(0.5rem);
}
@media (max-width:992px){
  .desktop-show{
    display: none !important;
  }
  .mobile-show{
    display: block !important;
  }
  .connect_btn_mobile{
    right: 200px;
  }
  #navbarScroll{
    position: absolute;
    top: 75px;
    right: 10px;
    background: #181B1A;
    width: 90%;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 17px 10px;
  }
  .collapsed>.navbar-toggler-icon {
    width: 1.1em !important;
    height: 1.1em !important;
    background-image: url('../img/toggle.svg') !important;
  }
  .navbar-toggler-icon {
    width: 1.1em !important;
    height: 1.1em !important;
    background-image: url('../img/close.svg') !important;
  }
  .navbar-toggler{
    padding: 10px 14px !important;
    background-color: #d1fee607 !important;
    border-radius: 10px !important;
  }
  .navbar-toggler:focus{
    box-shadow: none !important;
  }
  .menu-item{
    padding: 10px 0;
  }
  .social-item {
    background: #222725;
    margin-right: 17px;
    border-radius: 4px;
    padding: 5px 8px;
  }
  #dropdown-basic{
    color: rgba(255, 255, 255, 0.5) !important;
    background-color: #146c4300 !important;
    border-color: #13653f00 !important;
    padding-left: 16px !important;
  }
  .dropdown-menu{
    background: #ffffff00 !important;
    padding: 0 15px !important;
    border: none !important;
    font-size: 14px !important;
  }
  .dropdown-item{
    color: #B1B1B1 !important;
    padding: 10px 15px !important;
  }
  .dropdown-toggle::after{
    display: none !important;
  }
  .width-100{
    width: 100% !important;
  }
  .width-33-100{
    width: 100% !important;
    margin-top: 50px !important;
  }
  .width-33-50{
    width:50% !important;
  }
  .w-238{
    width:100% !important;
  }
  .font-36{
    font-size: 32px !important;
    line-height: 1.3em;
  }
  .font-32{
    font-size: 28px !important;
  }
  .w-25-100{
    width:100% !important;
  }
  .w-33-100{
    width:100% !important;
  }
  .w-41-100{
    width:100% !important;
  }
  .w-59-100{
    width:100% !important;
  }
  .w-75-100{
    width:100% !important;
  }
  .home-section1{
    min-height: 800px;
    background-size: unset;
  }
  .home-3-logos-row{
    max-width: 900px;
    margin: auto;
  }
  .w-450px{
    width:100%;
  }
  .ptb-50{
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .desktop-flex{
    display: block !important;
  }
  .mobile-left{
    text-align: left;
  }
  .gmi_dropdown_div{
    overflow: scroll !important;
  }
  .gmi_dropdown .font-16{
    font-size:12px !important;
  }
  .buy_gloop_inputs{
    text-align: left !important;
    padding-left: 38px;
  }
  .info[data-title]:after {
    display: none;
  }
  .info[data-title]:before {
    display: none;
  }
  .collateral_ratio{
    margin-left:37px;
  }
}

@media (max-width:900px){
  .connect_btn_mobile{
    right: 155px !important;
  }
}
@media (max-width:860px){
  .connect_btn_mobile{
    right: 140px !important;
  }
}
@media (max-width:770px){
  .connect_btn_mobile{
    right: 182px !important;
  }
}
@media (max-width:720px){
  .connect_btn_mobile{
    right: 164px !important;
  }
  .buy_gmi_inputs{
    width: 50px;
  }
}
@media (max-width:698px){
  .connect_btn_mobile{
    right: 150px !important;
  }
}
@media (max-width:645px){
  .connect_btn_mobile{
    right: 127px !important;
  }
}
@media (max-width:627px){
  .connect_btn_mobile{
    right: 121px !important;
  }
}
@media (max-width:580px){
  .connect_btn_mobile{
    right: 97px !important;
  }
}
@media (max-width:575px){
  .connect_btn_mobile{
    right: 78px !important;
  }
}
@media (max-width:375px){
  .gmi_dropdown {
    position: absolute;
    right: -19px;
    top: 47px;
    width: 116%;
    z-index: 9999999;
  }
}
